import './src/assets/styles/global.less';

export function onInitialClientRender() {
  const el = document.querySelector('meta[name="deployed:time"]');

  if (!el) {
    // Note, this won't work in dev mode because the values will still need
    // to be rendered. However, in production, all elements exist immediately
    // on initial page load.
    return;
  }

  console.log(`Deployed at ${el.getAttribute('content')}`);
}
